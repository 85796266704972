const Brazil = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_536_4751)'>
                <mask id='mask0_536_4751' maskUnits='userSpaceOnUse' x='3' y='3' width='18' height='18'>
                    <path
                        d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
                        fill='white'
                    />
                </mask>
                <g mask='url(#mask0_536_4751)'>
                    <path d='M3 3H21V21H3V3Z' fill='#6DA544' />
                    <path d='M12 6.52246L19.4355 11.9998L12 17.4771L4.56445 11.9998L12 6.52246Z' fill='#FFDA44' />
                    <path
                        d='M9.12425 10.7699C8.94934 11.1722 8.86301 11.6074 8.87113 12.046L14.5664 13.7968C14.8174 13.4384 14.9899 13.0311 15.0727 12.6015C13.6453 10.3058 10.8645 9.77845 9.12777 10.7734L9.12425 10.7699Z'
                        fill='#EEEEEE'
                    />
                    <path
                        d='M11.9895 8.87111C11.4753 8.8724 10.9693 9.0004 10.5164 9.24376C9.89301 9.58011 9.4022 10.118 9.12422 10.7695C10.1894 10.5434 11.2947 10.5932 12.3352 10.9144C13.3757 11.2356 14.3168 11.8175 15.0691 12.6047C15.2089 11.893 15.0971 11.1548 14.7527 10.5164C14.4842 10.0178 14.0853 9.60133 13.5987 9.31157C13.112 9.02181 12.5558 8.86957 11.9895 8.87111ZM10.4531 11.8067C9.91665 11.8049 9.38308 11.8855 8.87109 12.0457C8.87701 12.5477 9.00362 13.0409 9.24023 13.4836C9.43494 13.8457 9.69909 14.1658 10.0176 14.4258C10.3361 14.6857 10.7027 14.8803 11.0964 14.9985C11.4902 15.1167 11.9034 15.1562 12.3124 15.1146C12.7214 15.073 13.1182 14.9513 13.4801 14.7563C13.9098 14.5249 14.2794 14.1962 14.5594 13.7965C14.0659 13.1804 13.4413 12.6821 12.731 12.3379C12.0206 11.9937 11.2425 11.8122 10.4531 11.8067Z'
                        fill='#0052B4'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='clip0_536_4751'>
                    <rect width='18' height='18' fill='white' transform='translate(3 3)' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Brazil;
