import React, { FC, useContext, useEffect } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRequestCreateOrderMutation } from 'entities/order';
import dynamic from 'next/dynamic';
import { Loader as LoaderNova, Skeleton } from '@indriver/nova';
import { variables } from '@indriver/yrel';
import { DriverLicenseOutline, Like, People1 } from '@indriver/mireska';
import { IWhyChooseItem } from 'widgets/why-choose-us/why-choose-us';
import { IHowItWorkItem } from 'widgets/how-it-works/how-it-works';
import Head from 'next/head';
import { getCitiesByIds } from 'common/api/order';
import { Trans, useTranslation } from 'next-i18next';
import { IAutocompleteCity } from 'common/api/models';
import addMonths from 'date-fns/addMonths';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import useUTMRouter from 'shared/hooks/use-utm-router';
import Layout from 'shared/ui/layout';
import { GeoWrapper } from 'context/geo';
import { Experiments } from 'common/api/ab';
import PopularRoutesSecond from 'widgets/popular-routes-second/popular-routes-second';
import { ABTogglesContext } from 'context/ab-toggles';
import PopularRoutesFirst from 'widgets/popular-routes-first/popular-routes-first';
import { ContentWrapper, FormLoadingWrapper, OrderFormContainer, TextOrder } from 'widgets/order-form/ui';
import Heading from 'shared/ui/heading';
import Text from 'shared/ui/text';
import { Card } from 'shared/ui';
import { FormWrapper } from 'widgets/address-order-form/ui';
import { PopularRoutesSmall } from 'widgets/address-order-form/ui/popular-routes';
import AddressOrderForm from 'widgets/address-order-form/address-order-form';
import OrderForm from 'widgets/order-form/order-form';
import StaticOrderForm from 'widgets/static-order-form/static-order-form';
import { LANGUAGES_LIST } from 'widgets/header';
import { removeItemFromStorage } from 'shared/lib/storage';
import { ACTIVE_ORDER_STORAGE_KEY } from 'shared/constants/order';
import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
import axios from 'axios';
import { ICountry, ICountryRoutesMain } from 'pages/types';
import PopularIntercityRoutes from 'widgets/popular-intercity-routes/popular-intercity-routes';

const Footer = dynamic(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async () => import('widgets/footer/footer').then((module) => module.default),
    {
        ssr: false,
        loading: () => <Skeleton height='200px' />,
    },
);
const Header = dynamic(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async () => import('widgets/header/header').then((module) => module.default),
    {
        ssr: false,
        loading: () => <Skeleton height='100px' />,
    },
);

const MobileDownloadApp = dynamic(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async () => import('features/mobile-download-app/mobile-download-app').then((module) => module.default),
    {
        ssr: false,
        loading: () => <Skeleton height='300px' />,
    },
);

const WhyChooseUs = dynamic(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async () => import('../widgets/why-choose-us/why-choose-us').then((module) => module.default),
    {
        loading: () => <Skeleton height='300px' />,
    },
);

const WeCareSafety = dynamic(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async () => import('../widgets/we-care-safety/we-care-safety').then((module) => module.default),
    {
        ssr: false,
        loading: () => <Skeleton height='300px' />,
    },
);

const HowItWorks = dynamic(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async () => import('../widgets/how-it-works/how-it-works').then((module) => module.default),
    {
        ssr: false,
        loading: () => <Skeleton height='300px' />,
    },
);
const DownloadApp = dynamic(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async () => import('../widgets/download-app/download-app').then((module) => module.default),
    {
        ssr: false,
        loading: () => <Skeleton height='300px' />,
    },
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const AuthForm = dynamic(async () => import('../widgets/auth-form/auth-form').then((module) => module.default), {
    loading: () => <LoaderNova />,
});

interface IQueryParams {
    from?: number;
    to?: number;
    passengers?: number;
    price?: number;
    type?: 'private' | 'pool';
    departure_time?: string;
}

const { serverRuntimeConfig } = getConfig();

const CMS_HOST = serverRuntimeConfig.CMS_HOST || '';
const CMS_TOKEN = serverRuntimeConfig.CMS_TOKEN || '';

export async function getServerSideProps({ locale, query }: { locale: string; query: IQueryParams }) {
    try {
        let fromCity = null;
        let toCity = null;
        let passengers = null;
        let departureTime = null;
        let price = null;
        let rideType = null;
        // eslint-disable-next-line no-restricted-globals
        if (query.from && !isNaN(query.from) && query.to && !isNaN(query.to)) {
            const res = await getCitiesByIds([parseInt(String(query.from), 10), parseInt(String(query.to), 10)]);
            fromCity = res[query.from] ?? null;
            toCity = res[query.to] ?? null;
        }
        // eslint-disable-next-line no-restricted-globals
        if (query.passengers && !isNaN(query.passengers)) {
            if (query.passengers < 1) {
                passengers = 1;
            } else if (query.passengers > 50) {
                passengers = 50;
            } else {
                passengers = parseInt(String(query.passengers), 10);
            }
        }
        // eslint-disable-next-line no-restricted-globals
        if (query.price && !isNaN(query.price)) {
            price = parseInt(String(query.price), 10);
        }
        if (query.type && (query.type === 'private' || query.type === 'pool')) {
            rideType = query.type;
        }
        if (query.departure_time && isValid(new Date(query.departure_time))) {
            const today = new Date();
            const maxDate = addMonths(today, 1);
            const departureDate = new Date(query.departure_time);
            if (isBefore(departureDate, today)) {
                departureTime = today.toISOString();
            } else if (isAfter(departureDate, maxDate)) {
                departureTime = maxDate.toISOString();
            } else {
                departureTime = query.departure_time;
            }
        }

        let countries = [];

        const resExist = await axios.get<{ data: ICountry[] }>(`${CMS_HOST}/api/countries`, {
            headers: {
                Authorization: `Bearer ${CMS_TOKEN}`,
            },
            params: {
                locale,
                populate: {
                    routes: '*',
                },
            },
        });

        if (resExist?.data?.data?.length > 0) {
            countries = resExist?.data?.data;
        } else {
            const resEngLocale = await axios.get<{ data: ICountry[] }>(`${CMS_HOST}/api/countries`, {
                headers: {
                    Authorization: `Bearer ${CMS_TOKEN}`,
                },
                params: {
                    locale: 'en',
                    populate: {
                        routes: '*',
                    },
                },
            });
            countries = resEngLocale?.data?.data;
        }

        const routes = countries?.map((country) => {
            const { countryName, countrySlug, routes: countryRoutes, locale: countryLocale } = country?.attributes;

            const resRoutes = countryRoutes?.data
                ?.filter((route) => route?.attributes?.publishedAt && route?.attributes?.showOnMain)
                .map((route) => {
                    const {
                        cityFrom: { name: cityFromName },
                        cityTo: { name: cityToName },
                        routeSlug,
                        locale: routeLocale,
                    } = route.attributes;
                    return { cityFrom: cityFromName, cityTo: cityToName, routeSlug, locale: routeLocale };
                });
            return { countryName, countrySlug, routes: resRoutes, locale: countryLocale };
        });

        const routesObject = routes.reduce<Record<string, ICountryRoutesMain>>((acc, item) => {
            acc[item.countrySlug] = item;
            return acc;
        }, {});

        return {
            props: {
                fromCity,
                toCity,
                passengers,
                price,
                routes: routesObject,
                departureTime,
                rideType,
                ...(await serverSideTranslations(locale)),
            },
        };
    } catch (e) {
        Sentry.captureException(e);
        return {
            props: {
                error: JSON.stringify(e),
                ...(await serverSideTranslations(locale)),
            },
        };
    }
}

interface IGeneralProps {
    fromCity?: IAutocompleteCity;
    toCity?: IAutocompleteCity;
    passengers?: number;
    price?: number;
    departureTime?: string;
    rideType?: string;
    routes: Record<string, ICountryRoutesMain>;
}

const whyChooseUs: IWhyChooseItem[] = [
    {
        id: 1,
        title: 'landing.fair_prices',
        text: 'landing.choose_best_offer',
        icon: <Like size={24} color={variables['text-and-icon-primary']} />,
    },
    {
        id: 2,
        title: 'landing.verified_drivers',
        text: 'landing.choose_yours_driver',
        icon: <DriverLicenseOutline size={24} color={variables['text-and-icon-primary']} />,
    },
    {
        id: 3,
        title: 'landing.door_to_door_service',
        text: 'landing.can_be_picked_up_location',
        icon: <People1 size={24} color={variables['text-and-icon-primary']} />,
    },
];

const howItWorks: IHowItWorkItem[] = [
    { id: 1, text: 'landing.fill_out_order_form' },
    { id: 2, text: 'landing.wait_for_offers' },
    { id: 3, text: 'landing.accept_suitable' },
    { id: 4, text: 'landing.call_driver_discuss' },
];
const Page: FC<IGeneralProps> = ({ fromCity, toCity, passengers, price, departureTime, rideType, routes }) => {
    const requestOrder = useRequestCreateOrderMutation();
    const router = useUTMRouter();
    const { t } = useTranslation();
    const { toggles } = useContext(ABTogglesContext);
    const geo = useContext(GeoWrapper);

    const canonicalUrl = `https://intercity.indrive.com/${router.locale}${router.asPath === '/' ? '' : router.asPath}`
        .split('?')[0]
        .replace(/\/$/, '');

    useEffect(() => {
        if (router.locale) {
            localStorage.setItem('intercity_locale', router.locale);
        }
        removeItemFromStorage(ACTIVE_ORDER_STORAGE_KEY);
    }, []);

    const redesignExperiment = toggles?.experiments?.[Experiments.INTERCITY3_WEB_REDESIGN_MAIN];
    const redesignFormExperiment = toggles?.experiments?.[Experiments.INTERCITY3_WEB_REDESIGN_FORM];

    useEffect(() => {
        window.addEventListener('UC_UI_CMP_EVENT', () => {
            if (redesignExperiment?.cookieblock) {
                setTimeout(() => {
                    window?.UC_UI?.acceptAllConsents();
                    window?.UC_UI?.closeCMP();
                }, 0);
            }
        });
    }, [redesignExperiment]);

    const title =
        fromCity?.id && toCity?.id
            ? t(geo?.code === 'IN' ? 'landing.meta_title_india' : 'landing.meta_title', {
                  fromCity: fromCity.name,
                  toCity: toCity.name,
              })
            : t(geo?.code === 'IN' ? 'landing.page_title_india' : 'landing.page_title');

    const description =
        fromCity?.id && toCity?.id
            ? t('landing.meta_description', { fromCity: fromCity.name, toCity: toCity.name })
            : t(geo?.code === 'IN' ? 'landing.page_description_india' : 'landing.page_description');

    const experiment = toggles?.experiments?.[Experiments.INTERCITY3_WEB_POPULAR_ROUTES];
    const addressExperiment = toggles?.experiments?.[Experiments.INTERCITY3_WEB_NEW_ADDRESS_FIELDS];

    // TODO: Escalate stules
    return (
        <>
            <Head>
                <meta property='og:title' content={title} />
                <meta property='og:description' content={description} />
                <meta property='telegram:title' content={title} />
                <meta property='telegram:description' content={description} />
                <meta property='twitter:title' content={title} />
                <meta property='twitter:description' content={description} />
                <meta name='title' content={title} />
                <meta name='description' content={description} />
                <title>{title}</title>
                <meta property='og:image' content='/assets/cover.png' />
                <meta property='og:type' content='website' />
                <meta property='og:url' content={canonicalUrl} />
                <link rel='canonical' href={canonicalUrl} />
                {LANGUAGES_LIST?.filter(({ lang }) => lang !== 'default').map((item) => {
                    return (
                        <link
                            key={item.lang}
                            rel='alternate'
                            hrefLang={item.lang}
                            href={`https://intercity.indrive.com/${item.lang}`}
                        />
                    );
                })}
            </Head>
            {router.asPath.includes('/auth') && <AuthForm opened />}
            <MobileDownloadApp />

            <OrderFormContainer>
                <Header design='dark' />

                <ContentWrapper>
                    <Heading type='h1'>
                        <Trans
                            i18nKey={geo?.code === 'IN' ? 'landing.title_india' : 'landing.title'}
                            components={[
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                <span key={0} />,
                            ]}
                        />
                    </Heading>
                    <Text as='div' className={TextOrder} text='landing.explore_hundreds_routes' />
                </ContentWrapper>
                <FormWrapper>
                    {toggles?.isLoading ? (
                        <Card type='order' className={FormLoadingWrapper}>
                            <LoaderNova size={'xl'} />
                        </Card>
                    ) : (
                        <Card type='order'>
                            {redesignFormExperiment?.Enabled ? (
                                <StaticOrderForm
                                    requestOrder={requestOrder}
                                    fromCity={fromCity}
                                    toCity={toCity}
                                    passengers={passengers}
                                    rideType={rideType}
                                    departureTime={departureTime ? new Date(departureTime) : undefined}
                                />
                            ) : addressExperiment?.enabled ? (
                                <AddressOrderForm
                                    requestOrder={requestOrder}
                                    fromCity={fromCity}
                                    toCity={toCity}
                                    price={price}
                                    passengers={passengers}
                                    rideType={rideType}
                                    departureTime={departureTime ? new Date(departureTime) : undefined}
                                />
                            ) : (
                                <OrderForm
                                    requestOrder={requestOrder}
                                    fromCity={fromCity}
                                    toCity={toCity}
                                    passengers={passengers}
                                    rideType={rideType}
                                    departureTime={departureTime ? new Date(departureTime) : undefined}
                                />
                            )}
                        </Card>
                    )}
                    {experiment?.small && <PopularRoutesSmall />}
                </FormWrapper>
            </OrderFormContainer>

            <Layout>
                {experiment?.enabled ? (
                    experiment?.large ? (
                        <PopularRoutesFirst />
                    ) : experiment?.medium ? (
                        <PopularRoutesSecond />
                    ) : (
                        <></>
                    )
                ) : (
                    <></>
                )}
                <WhyChooseUs list={whyChooseUs} />
                <WeCareSafety />
                <HowItWorks list={howItWorks} />
                <DownloadApp />
                <PopularIntercityRoutes list={routes} />
            </Layout>
            <Footer />
        </>
    );
};
export default Page;
