import { forwardRef, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { ForwardArrowRight } from '@indriver/mireska';
import useIsRTL from 'shared/hooks/useIsRTL';
import { priceWithCurrencyCode } from 'shared/lib/price-with-currency-code';
import { useIntersection } from 'shared/hooks/use-intersection';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { useTranslation } from 'next-i18next';
import { RouteCardContainer, RouteName, RoutePrice } from './route-card-third.style';

interface IRouteCardProps {
    startPoint: string;
    finishPoint: string;
    currency: string;
    price?: number | null;
}

// TODO: запретить перенос "стрелочки"
// TODO: Добавить иконку валюты
const RouteCardThird = forwardRef<HTMLDivElement, IRouteCardProps & HTMLAttributes<HTMLDivElement>>(
    ({ startPoint, finishPoint, price, currency, onClick }, ref) => {
        const { direction } = useIsRTL();
        const { t } = useTranslation();

        const cardRef = useRef<HTMLDivElement>(null);

        const isInViewport = useIntersection(cardRef);
        const [sentEvent, setSentEvent] = useState(false);

        useEffect(() => {
            if (isInViewport && !sentEvent) {
                sendAnalyticsEvent('web.intercity.passenger.popular_r.view', {
                    from: startPoint,
                    to: finishPoint,
                    bid_price: price,
                    type: 'small',
                });
                setSentEvent(true);
            }
        }, [isInViewport, sentEvent]);

        return (
            <RouteCardContainer ref={ref} onClick={onClick}>
                <RouteName ref={cardRef}>
                    <span>{startPoint}</span>
                    <ForwardArrowRight size={24} dir={direction} />
                    <span>{finishPoint}</span>
                </RouteName>
                {Boolean(price) && (
                    <RoutePrice>
                        {t('order.from_rec_price', { price: priceWithCurrencyCode(price as number, currency) })}
                    </RoutePrice>
                )}{' '}
            </RouteCardContainer>
        );
    },
);

export default RouteCardThird;
