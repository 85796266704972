import { Input, IInputProps } from '@indriver/nova';
import { FC, memo, ReactNode } from 'react';
import { OrderInputCss, OrderInputWrapper, OrderInputValue } from './order-form.style';

interface IOrderInputProps {
    value: string | number;
    suffix: string | ReactNode;
}

export const OrderInputNumber: FC<IOrderInputProps & IInputProps> = memo(({ ...props }) => {
    return (
        <OrderInputWrapper value={props.value}>
            {props.value && <span className='placeholder'>{props.placeholder}</span>}
            <Input {...props} className={OrderInputCss} />
            {props.suffix && props.value && (
                <OrderInputValue>
                    {props.value} {props.suffix}
                </OrderInputValue>
            )}
        </OrderInputWrapper>
    );
});
