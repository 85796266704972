import RouteCardThird from 'features/route-card-third';
import React, { useRef, useState } from 'react';
import useWindowSize from 'shared/hooks/useWindowSize';
import RouteCardSecond from 'features/route-card-second/route-card-second';
import { Text } from 'shared/ui';
import { useRouter } from 'next/router';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { usePopularRoutes } from 'entities/popular-routes';
import { PopularContainer, ShowMoreContainer } from './order-form.style';

export const PopularRoutesSmall = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowSize();
    const [visible, setVisible] = useState(false);
    const router = useRouter();

    const popularRoutes = usePopularRoutes();

    const Component = width <= 520 ? RouteCardSecond : RouteCardThird;
    if (popularRoutes.length === 0) {
        return null;
    }

    return (
        <PopularContainer ref={containerRef}>
            {popularRoutes.slice(0, !visible ? 3 : 6).map((item) => (
                <Component
                    key={`${item.city_from.id}${item.city_to.id}`}
                    startPoint={item.city_from.name}
                    finishPoint={item.city_to.name}
                    price={item.price.private_price}
                    type='small'
                    currency={item.city_from.currency_code}
                    onClick={async () => {
                        sendAnalyticsEvent('web.intercity.passenger.popular_r.click', {
                            is_autofilled_from: !!item.city_from.name,
                            is_autofilled_to: !!item.city_to.name,
                            is_autofilled_price: !!item.price.private_price,
                            value_autofilled_from: item.city_from.name,
                            value_autofilled_to: item.city_to.name,
                            value_autofilled_price: item.price.private_price,
                        });
                        await router.push(
                            `?from=${item.city_from.id}&to=${item.city_to.id}&price=${item.price.private_price}`,
                            undefined,
                        );
                        sendAnalyticsEvent('web.intercity.passenger.popular_r.scroll');
                    }}
                />
            ))}

            {popularRoutes?.length > 3 && (
                <ShowMoreContainer>
                    <Text
                        text={!visible ? 'landing.show_more' : 'landing.show_less'}
                        onClick={() => setVisible(!visible)}
                    />
                </ShowMoreContainer>
            )}
        </PopularContainer>
    );
};
