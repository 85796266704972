import { FC, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { ForwardArrowRight } from '@indriver/mireska';
import useIsRTL from 'shared/hooks/useIsRTL';
import { priceWithCurrencyCode } from 'shared/lib/price-with-currency-code';
import { useIntersection } from 'shared/hooks/use-intersection';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { useTranslation } from 'next-i18next';
import { RouteCardContainer, RouteName, RoutePrice } from './route-card-second.style';

interface IRouteCardProps {
    startPoint: string;
    finishPoint: string;
    currency?: string;
    link?: string;
    type: string;
    price?: number | null;
    isStatic?: boolean;
}

// TODO: запретить перенос "стрелочки"
// TODO: Добавить иконку валюты
const RouteCardSecond: FC<IRouteCardProps & HTMLAttributes<HTMLAnchorElement>> = ({
    startPoint,
    finishPoint,
    price,
    currency,
    type,
    link,
    isStatic,
    onClick,
}) => {
    const { direction } = useIsRTL();

    const ref = useRef<HTMLAnchorElement>(null);
    const [sentEvent, setSentEvent] = useState(false);
    const isInViewport = useIntersection(ref);
    const { t } = useTranslation();

    useEffect(() => {
        if (isInViewport && !sentEvent && !isStatic) {
            sendAnalyticsEvent('web.intercity.passenger.popular_r.view', {
                from: startPoint,
                to: finishPoint,
                bid_price: price,
                type,
            });
            setSentEvent(true);
        }
    }, [isInViewport, sentEvent]);

    return (
        <RouteCardContainer ref={ref} onClick={onClick} href={link}>
            <RouteName>
                <p>
                    <span> {startPoint}</span>
                    <ForwardArrowRight size={24} dir={direction} />
                </p>
                <span>{finishPoint}</span>
            </RouteName>
            {Boolean(price) && (
                <RoutePrice>
                    {t('order.from_rec_price', { price: priceWithCurrencyCode(price as number, currency) })}
                </RoutePrice>
            )}
        </RouteCardContainer>
    );
};

export default RouteCardSecond;
