import {
    ChipItemStyle,
    DirectionsContainer,
    PopularRoutesWrapper,
    RoutesContainer,
} from 'widgets/popular-intercity-routes/popular-intercity-routes.style';
import React, { FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { ICountryRoutesMain } from 'pages/types';
import { Chip, Chips } from '@indriver/nova';
import useIsRTL from 'shared/hooks/useIsRTL';
import India from 'widgets/popular-intercity-routes/icons/India';
import Brazil from 'widgets/popular-intercity-routes/icons/Brazil';
import Colombia from 'widgets/popular-intercity-routes/icons/Colombia';
import { GeoWrapper } from 'context/geo';
import Button from 'shared/ui/button';
import { Trans, useTranslation } from 'next-i18next';
import { CountryType } from '@indriver/nova-core/dist/types/use-input-mask/use-phone-input';
import Link from 'next/link';
import { ChevronDownSmall, ChevronUpSmall } from '@indriver/mireska';

interface IPopularIntercityRoutesProps {
    list?: Record<string, ICountryRoutesMain>;
}

const countryIcons = {
    IN: <India />,
    BR: <Brazil />,
    CO: <Colombia />,
};

interface ICustomLinkProps {
    children?: ReactNode;
    href: string;
    locale?: string;
}

const CustomLink: FC<ICustomLinkProps> = (props) => {
    if (!props.href) return null;
    if (!props?.children || props?.children.toString() === '') return null;

    return (
        <Link href={props.href} locale={props.locale}>
            {props?.children?.toString()}
        </Link>
    );
};
const PopularIntercityRoutes: FC<IPopularIntercityRoutesProps> = ({ list }) => {
    const geo = useContext(GeoWrapper);
    const [active, setActive] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { direction } = useIsRTL();
    const { t } = useTranslation();
    const countries = useMemo(() => (list ? Object.keys(list) : []), [list]);

    useEffect(() => {
        if (list) {
            if (geo && countries.includes(geo?.code?.toLowerCase())) {
                setActive(geo?.code?.toLowerCase());
            } else {
                setActive(list?.[countries[0]]?.countrySlug?.toLowerCase());
            }
        }
    }, [geo, countries]);

    if (countries?.length === 0) {
        return null;
    }

    return (
        <PopularRoutesWrapper>
            <RoutesContainer>
                {countries?.length > 1 && list && (
                    <Chips
                        dir={direction}
                        multiple={false}
                        active={[active]}
                        size='l'
                        inline
                        // className={ChipsStyle}
                        onChange={(id) => {
                            if (id && id?.length) {
                                const [value] = id;
                                setActive(value);
                            }
                        }}>
                        {countries.map((country) => {
                            const countryCode = list?.[country]?.countrySlug?.toUpperCase() as CountryType;
                            return (
                                <Chip
                                    key={countryCode}
                                    id={list?.[country]?.countrySlug}
                                    className={ChipItemStyle}
                                    role='button'
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    // @ts-ignore
                                    prefix={countryIcons[countryCode]}
                                    active={active === list?.[country]?.countrySlug}>
                                    {list?.[country]?.countryName}
                                </Chip>
                            );
                        })}
                    </Chips>
                )}

                <h4>
                    <Trans
                        i18nKey='landing.intercity_routes_in_country'
                        values={{ country: list?.[active]?.countryName }}
                        components={[<CustomLink key={active} href={active} locale={list?.[active]?.locale} />]}
                    />
                </h4>

                {/* Возможно для SEO понадобиться рендерить все направления, но отображать только постране. Как решение рендерить все и через условие выставлять вisplay:none */}
                {list && list[active] && list[active]?.routes?.length > 0 && (
                    <DirectionsContainer isOpen={isOpen}>
                        {list[active]?.routes.map((route) => (
                            <Link
                                key={route.routeSlug}
                                href={`${active}/routes/${route.routeSlug}`}
                                locale={route.locale}>
                                {t('landing.from_to_direction', { from: route.cityFrom, to: route.cityTo })}
                            </Link>
                        ))}
                    </DirectionsContainer>
                )}
                {list && list[active]?.routes?.length > 15 && (
                    <Button
                        stretched
                        type='button'
                        text={!isOpen ? 'landing.see_all' : 'landing.see_less'}
                        postfix={!isOpen ? <ChevronDownSmall size={24} /> : <ChevronUpSmall size={24} />}
                        design={'secondary'}
                        onClick={() => setIsOpen(!isOpen)}
                    />
                )}
            </RoutesContainer>
        </PopularRoutesWrapper>
    );
};

export default PopularIntercityRoutes;
