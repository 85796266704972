const India = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_536_4745)'>
                <mask id='mask0_536_4745' maskUnits='userSpaceOnUse' x='3' y='3' width='18' height='18'>
                    <path
                        d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
                        fill='white'
                    />
                </mask>
                <g mask='url(#mask0_536_4745)'>
                    <path d='M3 8.625L12 7.5L21 8.625V15.375L12 16.5L3 15.375V8.625Z' fill='#EEEEEE' />
                    <path d='M3 3H21V8.625H3V3Z' fill='#FF9811' />
                    <path d='M3 15.375H21V21H3V15.375Z' fill='#6DA544' />
                    <path
                        d='M12 14.5312C13.398 14.5312 14.5312 13.398 14.5312 12C14.5312 10.602 13.398 9.46875 12 9.46875C10.602 9.46875 9.46875 10.602 9.46875 12C9.46875 13.398 10.602 14.5312 12 14.5312Z'
                        fill='#0052B4'
                    />
                    <path
                        d='M12 13.6875C12.932 13.6875 13.6875 12.932 13.6875 12C13.6875 11.068 12.932 10.3125 12 10.3125C11.068 10.3125 10.3125 11.068 10.3125 12C10.3125 12.932 11.068 13.6875 12 13.6875Z'
                        fill='#EEEEEE'
                    />
                    <path
                        d='M12 12.8438C12.466 12.8438 12.8438 12.466 12.8438 12C12.8438 11.534 12.466 11.1562 12 11.1562C11.534 11.1562 11.1562 11.534 11.1562 12C11.1562 12.466 11.534 12.8438 12 12.8438Z'
                        fill='#0052B4'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='clip0_536_4745'>
                    <rect width='18' height='18' fill='white' transform='translate(3 3)' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default India;
