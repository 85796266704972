import { FC, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { ChevronRight } from '@indriver/mireska';
import useIsRTL from 'shared/hooks/useIsRTL';
import { variables } from '@indriver/yrel';
import { priceWithCurrencyCode } from 'shared/lib/price-with-currency-code';
import { useIntersection } from 'shared/hooks/use-intersection';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { useTranslation } from 'next-i18next';
import { RouteCardContainer, RouteIcon, RouteName, RoutePrice } from './route-card.style';

interface IRouteCardProps {
    startPoint: string;
    finishPoint: string;
    currency: string;
    price?: number | null;
    onClick: () => void;
}

// TODO: запретить перенос "стрелочки"
// TODO: Добавить иконку валюты
const RouteCard: FC<IRouteCardProps & HTMLAttributes<HTMLDivElement>> = ({
    startPoint,
    finishPoint,
    currency,
    price,
    onClick,
}) => {
    const { direction } = useIsRTL();
    const { t } = useTranslation();
    const cardRef = useRef<HTMLDivElement>(null);
    const [sentEvent, setSentEvent] = useState(false);

    const isInViewport = useIntersection(cardRef);

    useEffect(() => {
        if (isInViewport && !sentEvent) {
            sendAnalyticsEvent('web.intercity.passenger.popular_r.view', {
                from: startPoint,
                to: finishPoint,
                bid_price: price,
                type: 'large',
            });
            setSentEvent(true);
        }
    }, [isInViewport, sentEvent]);

    return (
        <RouteCardContainer ref={cardRef} onClick={onClick}>
            <div>
                <RouteName>
                    {startPoint}&nbsp;– {finishPoint}
                </RouteName>
                {Boolean(price) && (
                    <RoutePrice>
                        {t('order.from_rec_price', { price: priceWithCurrencyCode(price as number, currency) })}
                    </RoutePrice>
                )}
            </div>
            <RouteIcon>
                <ChevronRight dir={direction} size={24} color={variables['text-and-icon-primary']} />
            </RouteIcon>
        </RouteCardContainer>
    );
};

export default RouteCard;
