import LandingHeading from 'shared/ui/landing-heading';
import useWindowSize from 'shared/hooks/useWindowSize';
import RouteCard from 'features/route-card/route-card';
import { Text } from 'shared/ui';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { usePopularRoutes } from 'entities/popular-routes';
import { PopularRoutesContainer, PopularRoutesList, ShowMoreContainer } from './popular-routes-first.style';

const PopularRoutesFirst = () => {
    const { width } = useWindowSize();
    const [visible, setVisible] = useState(false);
    const router = useRouter();

    const popularRoutes = usePopularRoutes();
    if (popularRoutes.length === 0) {
        return null;
    }

    return (
        <PopularRoutesContainer>
            <LandingHeading isTitle size={width <= 780 ? 'xxl' : 'xxxxl'} type='h2' text='landing.popular_routes' />
            <PopularRoutesList>
                {popularRoutes?.slice(0, !visible ? 3 : 6).map((item) => (
                    <RouteCard
                        key={`${item.city_from.id}${item.city_to.id}`}
                        startPoint={item.city_from.name}
                        finishPoint={item.city_to.name}
                        price={item.price.private_price}
                        currency={item.city_from.currency_code}
                        onClick={async () => {
                            sendAnalyticsEvent('web.intercity.passenger.popular_r.click', {
                                is_autofilled_from: !!item.city_from.name,
                                is_autofilled_to: !!item.city_to.name,
                                is_autofilled_price: !!item.price.private_price,
                                value_autofilled_from: item.city_from.name,
                                value_autofilled_to: item.city_to.name,
                                value_autofilled_price: item.price.private_price,
                            });
                            await router.push(
                                `?from=${item.city_from.id}&to=${item.city_to.id}&price=${item.price.private_price}`,
                                undefined,
                            );
                            sendAnalyticsEvent('web.intercity.passenger.popular_r.scroll');
                        }}
                    />
                ))}
            </PopularRoutesList>
            {popularRoutes?.length > 3 && (
                <ShowMoreContainer>
                    <Text
                        text={!visible ? 'landing.show_more' : 'landing.show_less'}
                        onClick={() => setVisible(!visible)}
                    />
                </ShowMoreContainer>
            )}
        </PopularRoutesContainer>
    );
};

export default PopularRoutesFirst;
