import { useQuery } from '@tanstack/react-query';
import { getPopularRoutes } from 'common/api/order';
import { useContext, useMemo } from 'react';
import { IAutocompleteCity } from 'common/api';
import { GeoWrapper } from 'context/geo';

export const usePopularRoutes = () => {
    const geo = useContext(GeoWrapper);

    const { data: popularRoutes = [] } = useQuery(
        ['popularRoutes'],
        async () => {
            if (geo?.id) {
                const res = await getPopularRoutes(geo?.id);
                return res.filter((route) => route.city_from.id !== route.city_to.id);
            }

            return [];
        },
        {
            enabled: !!geo?.id,
            staleTime: 60000, // время, в течение которого данные считаются актуальными (в миллисекундах)
            cacheTime: 3600000, // время, в течение которого данные будут храниться в кэше (в миллисекундах)
        },
    );

    return popularRoutes;
};

export const useUniquePopularRoutes = () => {
    const popularRoutes = usePopularRoutes();

    const uniquePopularRoutes: IAutocompleteCity[] = useMemo(() => {
        const citySet = new Map();

        popularRoutes.forEach((route) => {
            if (!citySet.has(route.city_from.id)) {
                citySet.set(route.city_from.id, route.city_from);
            }
            if (!citySet.has(route.city_to.id)) {
                citySet.set(route.city_to.id, route.city_to);
            }
        });

        return Array.from(citySet.values());
    }, [popularRoutes]);

    return uniquePopularRoutes;
};
