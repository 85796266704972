const Colombia = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_536_4757)'>
                <mask id='mask0_536_4757' maskUnits='userSpaceOnUse' x='3' y='3' width='18' height='18'>
                    <path
                        d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
                        fill='white'
                    />
                </mask>
                <g mask='url(#mask0_536_4757)'>
                    <path d='M3 16.5002L11.993 15.4561L21 16.5002V21.0002H3V16.5002Z' fill='#D80027' />
                    <path d='M3 12L12.123 10.9102L21 12V16.5H3V12Z' fill='#0052B4' />
                    <path d='M3 3H21V12H3V3Z' fill='#FFDA44' />
                </g>
            </g>
            <defs>
                <clipPath id='clip0_536_4757'>
                    <rect width='18' height='18' fill='white' transform='translate(3 3)' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Colombia;
